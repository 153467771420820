.projectMobileImage {
  width: 40%;
  height: auto;
  margin-bottom: 1rem;
}

.projectMobileImage:hover {
  opacity: 0.9;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.projectDesktopImage {
  width: 100%;
  height: auto;
  margin-top: -.5rem;
  margin-bottom: 1rem;
}

.projectDesktopImage:hover {
  opacity: 0.9;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.projectLink:hover {
  transform: scale(1.1);
}