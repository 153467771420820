.navLink:hover {
  transform : scale(1.1);
  color: white;
}

@media screen and (max-width: 1200px) {
  .appHeadline {
    font-size: 3.25rem !important;
  }
  .appSubHeadline {
    font-size: 3.25rem !important;
  }
}

@media screen and (max-width: 1000px) {
  .appHeadline {
    font-size: 3rem !important;
  }
  .appSubHeadline {
    font-size: 3rem !important;
  }
}

@media screen and (max-width: 900px) {
  .appHeadline {
    font-size: 2.5rem !important;
  }
  .appSubHeadline {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 800px) {
  .appHeadline {
    font-size: 2.25rem !important;
  }
  .appSubHeadline {
    font-size: 2.25rem !important;
  }
}

@media screen and (max-width: 700px) {
  .appHeadline {
    font-size: 2rem !important;
  }
  .appSubHeadline {
    font-size: 1.8rem !important;
  }
}

@media screen and (max-width: 600px) {
  .appHeadline {
    font-size: 1.75rem !important;
  }
  .appSubHeadline {
    font-size: 1.5rem !important;
  }
}

@media screen and (max-width: 500px) {
  .appHeadline {
    font-size: 1.5rem !important;
  }
  .appSubHeadline {
    font-size: 1.3rem !important;
  }
  .mobileMenuSubHeadline {
    font-size: 1.25rem !important;
  }
}

@media screen and (max-width: 440px) {
  .appSubHeadline {
    font-size: 1.1rem !important;
  }
  .appSubSubHeadline {
    font-size: .9rem !important;
  }
}


@media screen and (max-width: 400px) {
  .appHeadline {
    font-size: 1.25rem !important;
  }
  .appSubHeadline {
    font-size: 1rem !important;
  }

  .appSubSubHeadline {
    font-size: .7rem !important;
  }

  .mobileMenuSubHeadline {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 350px) {
  .appSubHeadline {
    font-size: .8rem !important;
  }
  .appSubSubHeadline {
    font-size: .6rem !important;
  }
  .mobileMenuSubHeadline {
    font-size: .9rem !important;
  }
}

@media screen and (max-width: 310px) {
  .appSubHeadline {
    font-size: .7rem !important;
  }
  .appSubSubHeadline {
    font-size: .5rem !important;
  }
  .mobileMenuSubHeadline {
    font-size: .8rem !important;
  }
}


@media screen and (max-width: 280px) {
  .appHeadline {
    font-size: 1rem !important;
  }
  .appSubHeadline {
    font-size: .6rem !important;
  }
 
}