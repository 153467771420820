@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&family=Commissioner&family=Cormorant&family=Courgette&family=Macondo&family=Nothing+You+Could+Do&family=Permanent+Marker&family=Pinyon+Script&family=Press+Start+2P&family=Roboto+Flex&family=Rubik&display=swap');


body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  /* background-color: hsla(120,100%,50%,1) */

}


html {
  overflow-x: hidden;
 
}

.permanent-marker {
  font-family: 'Permanent Marker'
}

.rubik {
  font-family: 'Rubik'
}

:root {
  --toastify-text-color-light: #000;
}

.Toastify__toast-theme--light {

  --toastify-text-color-light: #000;
}