.northern-lights-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* opacity: 0.5; */
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}


